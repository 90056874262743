import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import Method from "../../Components/Method/Method";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const MethodPage = () => {
  return (
    <>
      <Seo locale="it" page="method" />
      <Header lang="it" />
      <HamburgerMenu />
      <Method lang="it" />
      <Partnership className="d-block d-md-none" lang="it" />
      <Footer display={false} lang="it" />
    </>
  );
};

export default MethodPage;
